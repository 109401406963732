// Footer.js
import React from "react";
import { FaInstagram } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaYelp } from "react-icons/fa6";
import "../Styles/FooterStyle.css";

function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="infoBox1">
          <h3>CONTACT US</h3>
          <span>geoff@glelectronics.net</span>
          <span>(480) 468 9677</span>
          <span>Hours of Operation:</span>
          <span>Mon-Fri: 8AM - 8PM</span>
          <span>Sat: 10AM - 6PM</span>
          <span>Sun: CLOSED</span>
        </div>

        <div className="LocationCont">
            <h3>LOCATIONS</h3>
          <span>
            Mesa
            <br/>
            Tempe
            <br/>
            Phoenix
            <br/>
            Chandler
            <br/>
            Gilbert
            <br/>
            Scottsdale
            <br/>
            Glendale
            <br/>
            and more
          </span>
        </div>

        <div className="infoBox3">
          <h3>LICENSED / BONDED / INSURED</h3>
            <span>
              Surveillance
              <br/>
              Access Control
              <br/>
              Netowrking
              <br/>
              Audio / Video
              <br/>
              Alarm Systems
              <br/>
              Structured Wiring
              <br/>
              Voice Over IP
              <br/>
              Intercom Systems
              <br/>
            </span>
        </div>

        <div className="infoContainer">
          <h3>FOLLOW US ON SOCIALS</h3>
          <div className="socials">
            <a
              href="https://www.instagram.com/glelectronics18?igsh=aGVwbXJmbXJ5Nmjq/"
              target="_blank"
              rel="noopener noreferrer"
              className="socialLink"
              aria-label="Follow us on Instagram"
            >
              <FaInstagram size={30} style={{ marginRight: "5px" }} />
            </a>

            <a
              href="https://www.facebook.com/profile.php?id=61550683222711&mibextid=uzlsIk"
              target="_blank"
              rel="noopener noreferrer"
              className="socialLink"
              aria-label="Follow us on Facebook"
            >
              <BsFacebook size={30} style={{ marginLeft: '15px' }} />
            </a>

            <a
              href="https://www.linkedin.com/company/glelectronics/about/"
              target="_blank"
              rel="noopener noreferrer"
              className="socialLink"
              aria-label="Follow us on LinkedIn"
            >
              <FaLinkedin size={31} style={{ marginLeft: '15px' }} />
            </a>

            <a
              href="https://www.yelp.com/biz/gl-electronics-mesa?osq=GL+electronics&override_cta=Get+pricing"
              target="_blank"
              rel="noopener noreferrer"
              className="socialLink"
              aria-label="Find us on Yelp"
            >
              <FaYelp size={30} style={{ marginLeft: '15px' }} />
            </a>
          </div>
          <span className="copyStyle">&copy; Copyright 2023 - GL Electronics, LLC. <br /> All rights reserved</span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
