// Residential.js

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import Image1 from "../WebImg/ResImg/Interior1.webp";
import Image2 from "../WebImg/ResImg/Res_1.webp";
import Image3 from "../WebImg/ResImg/Net_1.webp";
import { IoIosArrowForward } from "react-icons/io";
import "../Styles/Res_Style.css";
import "../index.css";

function Residential() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <>
      <Helmet>
        <title>Residential Security Solutions - GL Electronics</title>
        <meta 
          name="description" 
          content="Explore GL Electronics' residential security solutions, including home surveillance installation, CCTV camera setup, and advanced networking to protect your home across Arizona." 
        />
      </Helmet>

      {/* Header */}
      <header className="headerR">
        <div className="headerTitleR">
          <div>
            <h1 className="headingStyleR">Residential Security Solutions</h1>
          </div>
        </div>
          <div className="IMGcontainerR">
            <div className="IMGcardHeaderR" style={{ '--background-image': `url(${Image1})`}}> </div>
          </div>
      </header>

      {/* Introduction */}
      <section>
        <div className="infoBoxTR">
          <p>
            Throughout the Valley, we offer a comprehensive range of <strong>home surveillance installation</strong> solutions, 
            including high-definition <strong>security camera systems</strong>, motion detection sensors, and smart integration features, 
            tailored to meet your specific security needs. Our <strong>CCTV installation</strong> and advanced electronic systems ensure that your home is protected with the latest in <strong>security technology</strong>.
          </p>
          <Link to="../Contact" className="infoBoxBtn" style={{ top: 50 }}>
            Schedule Now! <IoIosArrowForward style={{color: 'white'}} size={20} />
          </Link>
        </div>
      </section>

      {/* Surveillance */}
      <section>
        <div className="TotalContR">
          <div className="container1R" style={{ marginBottom: '20px', justifyContent: 'center' }}>
            <LazyLoad>
              <div className="IMGholderR" style={{ '--background-image': `url(${Image2})`}} alt="Residential home surveillance system"></div>
            </LazyLoad>
            <div className="infoBoxR infoBoxCamR">
              <h2>Home Surveillance Installation</h2>
              <p>
                Our <strong>home surveillance</strong> services offer peace of mind by providing round-the-clock monitoring of your property, ensuring the safety and security of your home. Our <strong>CCTV camera installation</strong> is designed to deliver high-quality video and reliable performance.
              </p>                 
              <ul style={{ listStyle: 'none' }}>
                <li>24/7 FULL COLOR</li>
                <li>5MP & 8MP FIXED WIDE ANGLE</li>
                <li>5MP & 8MP VARIFOCAL</li>
              </ul>
              <p>
                To learn more about the <strong>CCTV systems</strong> and technologies we offer, feel free to check out our partner's website:
                <br/>
                <a style={{ textDecoration: 'none', color: 'blue' }} href="https://www.cctvcore.com" target="_blank" rel="noopener noreferrer">www.cctvcore.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Networking */}
      <section>
        <div className="TotalContR">
          <div className="container1R" style={{ marginBottom: '20px', justifyContent: 'center', paddingBottom: '50px' }}>
            <div className="infoBoxR infoBoxNetR">
              <h2>Networking Security Solutions for Homes</h2>
              <p>
                Our home networking service enhances connectivity within your household, enabling seamless communication and sharing of resources among devices. Our <strong>network security solutions</strong> are designed to protect your digital assets and ensure secure connectivity.
                <br/><br/>
                With our expertise in home networking, we create custom solutions that prioritize security, efficiency, and scalability to support your home security system.
              </p>
            </div>
            <LazyLoad>
              <div className="IMGholderR" style={{ '--background-image': `url(${Image3})`}} alt="Home networking setup"></div>
            </LazyLoad>
          </div>
        </div>
      </section>

      {/* Other Services */}
      <section>
        <div className="bottomContainterR">
          <div className="infoBoxRend">
            <h2>Other Residential Services</h2>
            <p>
              In addition to <strong>CCTV installation</strong>, we specialize in home access control and alarm systems. Our <strong>access control solutions</strong> provide advanced security measures to manage and monitor entry points, ensuring only authorized individuals can access your home, adding an extra layer of safety for your family and property.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Link */}
      <nav>
        <Link to="../Contact" className="CButtonR" style={{ top: 50 }}>
          Ready to Secure your Home <IoIosArrowForward style={{color: 'white'}} size={20} />
        </Link>
      </nav>
    </>
  );
}

export default Residential;
