import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Image1 from "../WebImg/ComImg/Business_pic.webp";
import Image2 from "../WebImg/ComImg/wires.webp";
import Image3 from "../WebImg/ComImg/IMG_3761.webp";
import Image4 from "../WebImg/ComImg/IMG_3755.webp";
import CallNumCom from "../Components/CallNumCom";
import { IoIosArrowForward } from "react-icons/io";
import "../Styles/Com_Style.css";
import "../index.css";

function Commercial() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <>
      <Helmet>
        <title>Commercial Security and Monitoring - GL Electronics</title>
        <meta
          name="description"
          content="GL Electronics offers comprehensive commercial security solutions, including CCTV installation, network security, access control, and alarm systems, tailored for businesses in Arizona."
        />
      </Helmet>

      {/* Header */}
      <header className="headerCS">
        <div className="headerTitleCS">
          <div>
            <h1 className="headingStyleCS">Commercial Monitoring and Security Solutions</h1>
          </div>
        </div>
        <div className="IMGcontainerCS">
          <div className="IMGcardHeaderC" style={{ '--background-image': `url(${Image1})` }}> </div>
        </div>
      </header>

      {/* Introduction */}
      <section>
        <div className="TotalContC">
          <main className="TextContainerC">
            <div className="infoBoxC">
              <h2>Empowering Your Commercial Security</h2>
              <p>
                Enhance the safety and protection of your business with our comprehensive <strong>security camera system</strong> solutions. Our advanced electronic systems are designed to meet the complex needs of commercial environments, including <strong>CCTV installation</strong> for continuous monitoring.
                <br />
                <ul style={{ listStyle: 'none' }}>
                  <li>Structured Wiring</li>
                  <li>Live Monitoring</li>
                  <li>Alarm Systems</li>
                  <li>Audio / Video</li>
                </ul>
                <CallNumCom />
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Surveillance</h2>
              <p>
                Monitor and secure your premises with advanced <strong>CCTV surveillance systems</strong> tailored to your business needs. Our <strong>security camera systems</strong> provide high-definition video and reliable performance to keep your business safe.
                <br />
                <br />
                Want to know more about our camera options? Check out the <strong>cameras</strong> we use by visiting <a href="https://www.cctvcore.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>www.cctvcore.com</a>.
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Alarm Systems</h2>
              <p>
                Stay vigilant and respond swiftly to any security breaches with our state-of-the-art <strong>alarm systems</strong>. Our <strong>advanced security</strong> solutions are designed to protect your business around the clock.
                <br />
                <br />
                <strong>Ask about our 24/7 Monitoring!</strong>
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Access Control</h2>
              <p>
                Regulate entry and restrict unauthorized access to sensitive areas of your business premises with our <strong>access control solutions</strong>. Ensure your business's security with cutting-edge access management technologies.
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Network Security Solutions</h2>
              <p>
                Protect your digital assets with our comprehensive <strong>network security solutions</strong>. We provide secure and efficient networking for businesses, ensuring your data remains protected against cyber threats.
              </p>
            </div>
          </main>

          <aside className="IMGcontainerC">
            <div>
              <div className="IMGcardVC" style={{ '--background-image': `url(${Image2})` }} alt="Commercial structured wiring setup for security camera systems"></div>
              <div className="IMGcardVCM" style={{ '--background-image': `url(${Image3})` }} alt="Commercial network security setup with CCTV cameras"></div>
            </div>
            <div>
              <div className="IMGcardVC" style={{ '--background-image': `url(${Image4})` }} alt="Business CCTV camera installation in Arizona"></div>
            </div>
          </aside>
        </div>
      </section>

      {/* Contact Link */}
      <div>
        <Link className="CButtonC" to="../Contact" style={{ top: 150 }}>
          LET'S SECURE YOUR BUSINESS.
          CONTACT US!
          <IoIosArrowForward style={{ color: 'white' }} size={35} />
        </Link>
      </div>
    </>
  );
}

export default Commercial;
