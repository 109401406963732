import React, { useState, useEffect } from 'react';
import '../Styles/SlideShowStyle.css';
import { IoMdStar } from "react-icons/io";
import { GoDot } from "react-icons/go";
import { GoDotFill } from "react-icons/go";


const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hovered, setHovered] = useState(false);

  const slides = [
    <div>
      <div className='reviewboxStyle'>
        <h3>
            Dennis Chesney
            <br/>
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
        </h3>
        <p>
        GL has been taking care of my video security needs for a couple of years now. When they approached me with the option of installing a new alarm system, I did not hesitate as their service has always been top notch. If you are looking for professionals to take care of your home or business, look no further. GL Electronics is the best.
            <br/>
            <br/>
            April 10th, 2024
            <br/>
            <br/>
        <GoDotFill />
        <GoDot />
        <GoDot />
        <GoDot />
        </p>
      </div>
    </div>,

    /*************************_box2_***************************************/
    <div >
      <div className='reviewboxStyle'>
        <h3>
            Victor Lopez
            <br/>
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            </h3>
            <p>
            Excellent service! Needed additional security around my home and I am very pleased with GL Electronics. Great customer service, communication, and punctuality. They had all my cameras installed in one day. The camera quality is extremely clear and the app is easy to use. Definitely recommend Geoff & his team!
            <br/>
            <br/>
            March 9th, 2024
            <br/>
            <br/>
            <GoDot />
            <GoDotFill />
            <GoDot />
            <GoDot />
            </p>
      </div>
    </div>,
    /*************************_box3_***************************************/
    <div >
      <div className='reviewboxStyle'>
        <h3>
         GW Distributing LLC
            <br/>
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            </h3>
            <p>
            What a great Company to work with! Geoff and all his employees are the best to work with. We had them install 20 Security Cameras and when it came time for a new Alarm system we called them. Could not be happier with all their work and service.
            <br/>
            <br/>
            May 11th, 2024
            <br/>
            <br/>
            <GoDot />
            <GoDot />
            <GoDotFill />
            <GoDot />
            </p>
      </div>
    </div>,
    /*************************_box4_***************************************/
    <div >
      <div className='reviewboxStyle'>
        <h3>
         Anthony Foster
            <br/>
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            <IoMdStar style={{ color: 'gold' }} />
            </h3>
            <p>
            GL Electronics took over my home security account,  Geoff came in and gave me great service. I was having problems with my alarm panel they replaced it and now it works great... Thank you Geoff and Jesus.
            <br/>
            <br/>
            Feburary 21, 2024
            <br/>
            <br/>
            <GoDot />
            <GoDot />
            <GoDot />
            <GoDotFill />
            </p>
      </div>
    </div>,

 
  ];

  useEffect(() => {
    if (hovered) return;

    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [hovered]);

  return (
    <div
      className="slideshow-container"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slideshow-card ${index === currentSlide ? 'active-slide' : ''}`}
        >
          {slide}
        </div>
      ))}
    </div>
  );
};

export default Slideshow;
