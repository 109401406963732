//MenuDrop.js

import React from "react";
import { Link } from "react-router-dom";
import '../Styles/HeaderStyle.css';

function MenuBar({ closeMenu, isMenuOpen }) {
  return (
    <nav className={`menuBox ${isMenuOpen ? 'menuBoxOpen' : 'menuBoxClosed'}`}>
      <div className="menuBoxStyle">
        <Link to="../Residential" style={{ textDecoration: 'none', color: 'black', fontSize: '1.2rem' }} onClick={closeMenu}>
          Residential
        </Link>
      </div>
      <div className="menuBoxStyle">
        <Link to="../Commercial" style={{ textDecoration: 'none', color: 'black', fontSize: '1.2rem' }} onClick={closeMenu}>
          Commercial
        </Link>
      </div>
      <div className="menuBoxStyle">
        <Link to="../Contact" style={{ textDecoration: 'none', color: 'black', fontSize: '1.2rem' }} onClick={closeMenu}>
          Contact
        </Link>
      </div>
    </nav>
  );
}

export default MenuBar;
