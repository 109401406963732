import React from "react";
import "../Styles/HeaderStyle.css";

function CallNum() {
  const phoneNumber = '(480) 468 9677';
  
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="callNumBubble" onClick={handleCallClick} style={{ cursor: 'pointer' }}>
      <span className="callNumText">{phoneNumber}</span>
    </div>
  );
}

export default CallNum;
