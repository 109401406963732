import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import { useSpring, animated } from "@react-spring/web";

import '../Styles/HeaderStyle.css';

function Navigation() {

  
  return (
    <animated.div>
      <nav>

        <Link
          to="/Residential"
          className="contactStyle"
          style={{textDecoration: 'none', borderRadius: 15,}}
        >
          Residential
        </Link>
        <Link
          to="/Commercial"
          className="contactStyle"
          style={{textDecoration: 'none', borderRadius: 15,}}
        >
          Commercial
        </Link>
        <Link
          to="/Contact"
          className="contactStyle"
          style={{textDecoration: 'none', borderRadius: 15,}}
        >
          Contact
        </Link>
      </nav>
    </animated.div>
  );
}

export default Navigation;
