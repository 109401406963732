import React, { useEffect } from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing head
import { Link } from 'react-router-dom';
import Image1 from '../WebImg/CITY.webp';
import Image3 from "../WebImg/CloseC.webp";
import Image4 from "../WebImg/IMG_3659.webp";
import Image5 from "../WebImg/WhiteC.webp";
import LazyLoad from 'react-lazy-load';
import { TbDeviceCctv } from "react-icons/tb";
import { RiAlarmWarningLine } from "react-icons/ri";
import { MdOndemandVideo } from "react-icons/md";
import { IoIosGitNetwork } from "react-icons/io";
import Slideshow from "../Components/SlideShow";
import { IoIosArrowForward } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";

import "../Styles/SlideShowStyle.css";
import '../Styles/Home_style.css';
import "../index.css";

function Home() {
  return (
    <div>
      {/* Set Meta Description */}
      <Helmet>
        <title>GL Electronics | Secure Your World with Advanced Security Solutions</title>
        <meta
          name="description"
          content="GL Electronics provides advanced security solutions for homes and businesses across the Phoenix Valley, including CCTV surveillance, alarms, video solutions, and network security."
        />
      </Helmet>

      {/* Header */}
      <header className="HeaderContainerH">
        <div className="HeaderTitleH">
            <h1 className="headingStyleH">
                SECURE YOUR WORLD WITH ADVANCED SECURITY SOLUTIONS
            </h1>
        </div>
        <div className="IMGcontainerH">
          <div className="IMGcardH" style={{ '--background-image': `url(${Image1})` }}></div>
        </div>
      </header>

      {/* Promo */}
      <Link style={{textDecoration: 'none'}} to="/Contact">
        <section className="PromoH">
          <div className="TitlePH">
            <div className="PromoContH" style={{marginTop: 4, fontSize: '1.4rem', textIndent: 25, fontWeight: 'bold'}}>PROMOTION:</div>
          </div>

            <div className="Wtext">
              <p>FREE panel with monitoring agreement!</p>
              <div className="lineBreakH"></div>
              <p >24/7 professional monitoring starting at $29.99!</p>
            </div>
            <div className="Mtext">
              <p>
                FREE panel with monitoring agreement!
              <br/>
                24/7 professional monitoring starting at $29.99!
              </p>
                <IoIosArrowForward style={{color: 'white'}} size={50} />
          
            </div>
        </section>
      </Link>

      {/* Main Body */}
      <main className="MainBodyH">
        {/* TextBody */}
        <article className="TextBodyH">
          <h2>Security Camera Services For your home</h2>
          <div className="HStotalcont">
            <div className="faHome"><FaHome/></div>

            <div className="hService">
              <p>
                  For <strong>home surveillance</strong>, we offer a wide range of security services tailored to meet the unique needs for all<strong> residential homes </strong> across the <strong>Phoenix Valley</strong>. Check out our Residential services page to see what kind of <strong>security cameras, alarms</strong> and other technologies we use. Our commitment to safety and peace of mind extends throughout the region, ensuring that your property remains protected no matter where you are. 
              </p>
            </div>
          </div>

          <h2>Security Camera Services For your Business</h2>
          <div className="BStotalCont">
            <div className="faBuilding"><FaBuilding/></div>

            <div className="bService">
              <p>
                For businesses, we provide <strong>security solutions</strong> including cutting-edge <strong>surveillance cameras </strong>for businesses, advanced alarm systems, and business level <strong> camera installation</strong> services. Our expert technicians will assess your business premises and design a <strong>customized security solution </strong> to meet your specific requirements, ensuring the safety and security of your staff, customers, and assets. 
                <br/>
                We now also offer Financing for our <strong>Commercial Services</strong>
              </p>
            </div>
          </div>
        </article>


          <Link style={{textDecoration: 'none'}} to="../Contact">
            <nav className="ConsultBtn">
                <span >Schedule your Free Consultation NOW!</span>
                  <IoIosArrowForward style={{color: 'white'}} size={20} />
            </nav>
          </Link>

        {/* IconBody */}
        <section className="IconBodyH">
          <div className="IconCardH">
            <div className="iconTextV">
              <TbDeviceCctv size={110}/>
              <span style={{textAlign: 'center'}}> CCTV Surveillance</span>
            </div>
            <div className="iconTextV">
              <p>
              Our <strong>CCTV surveillance</strong> systems provide 24/7 monitoring to ensure the safety of your property.
              </p>
            </div>
          </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <IoIosGitNetwork size={110}/>
          <span style={{textAlign: 'center'}}>Networking Security Solutions</span>
        </div>
        <div className="iconTextV">
          <p>
            Our <strong>network security solutions</strong> enable seamless communication and resource sharing among devices.
          </p>
        </div>

        </div>
      </section>

      <section className="IconBodyH">
        <div className="IconCardH">
          <div className="iconTextV">
            <MdOndemandVideo size={110}/>
            <span style={{textAlign: 'center'}}>Video Solutions</span>
          </div>
          <div className="iconTextV">
            <p>
                Our <strong>video solutions</strong> monitor your house or business, ensuring safety and a peace of mind.
              </p>
          </div>
        </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <RiAlarmWarningLine size={110}/>
          <span style={{textAlign: 'center'}}>Alarm Systems</span>
        </div>
        <div className="iconTextV">
          <p>
          with our <strong>alarm systems</strong> we keep you protected with swift responses to security breaches.
          </p>
        </div>
      </div>
    </section>

    {/* Insert partnership slide here */}

    <section className="tempbx">
      <div>
        <span>Temp text</span>
      </div>

    </section>

     

        {/* IMG Body */}
        <section className="IMGbodyH">
          <aside className="IMGrow" style={{marginRight: '15px'}}>
            <div style={{marginRight: '15px'}}>
              <LazyLoad>
                <div className="IMGcardVH0" style={{ '--background-image': `url(${Image5})`}} alt="White Security Camera"></div>
              </LazyLoad>
              <LazyLoad>
                <div className="IMGcardVH1" style={{ '--background-image': `url(${Image3})`}} alt="Close up of Camera Installation"></div>
              </LazyLoad>
            </div>
      
              <div className="IMGVertH">
                <LazyLoad>
                  <div className="IMGcardVH0" style={{ '--background-image': `url(${Image4})`}} alt="Surveillance Camera Setup In Arizona"></div>
                </LazyLoad>
              </div>
        
          </aside>

          <article className="VertBox">
            <div className="infoBoxH">
              <h2>About Us</h2>
              <p style={{fontSize: '1.2rem'}}>
                GL Electronics is a locally owned and operated low voltage specialist servicing the major Phoenix metropolitan area since 2017. We've served homes and businesses, ranging from <strong>Mesa, Tempe, Chandler, Phoenix, Gilbert, Scottsdale, Glendale</strong> and many more! Our knowledgeable team members are trained to offer custom tailored solutions to fit every client's needs. We specialize in intuitive and advanced security electronics technology at competitive prices. 
                <br/>
                <br/>
                Look at some of our reviews below!
              </p>
            </div>

            <Slideshow />
            
          </article>
        </section>

        <nav>
          <Link to="../Contact" className="CButtonH">
          Get Started  <IoIosArrowForward style={{color: 'white'}} />
          </Link>
        </nav>
      </main>
    </div>
  )
}

export default Home;